<footer class="footer">
    <small>
        © {{ year | date:'yyyy'}} Santander
    </small>

    <small *ngIf="!ENV.production" style="color: #f01;">
        <strong>Ambiente de desenvolvimento</strong>
    </small>
    <!--     <small>
            <a [routerLink]="" (click)="showVersion()">Versão</a>
        </small> -->
</footer>

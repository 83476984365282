import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutLoggedComponent } from './core/layout/layout-logged/layout-logged.component';
import { LayoutLogoutComponent } from './core/layout/layout-logout/layout-logout.component';
import { RedirectIfAuthenticatedGuard } from './shared/middlewares/redirect-if-authenticated.guard';
import { RedirectIfNotAuthenticatedGuard } from './shared/middlewares/redirect-if-not-authenticated.guard';

const routes: Routes = [
    { path: '', redirectTo: '/insertions', pathMatch: 'full' },
    {
        path: 'profile',
        data: { base: true },
        component: LayoutLoggedComponent,
        canActivate: [RedirectIfNotAuthenticatedGuard],
        children: [
            { path: '', loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule) }
        ]
    },
    {
        path: 'users',
        data: { base: true },
        component: LayoutLoggedComponent,
        canActivate: [RedirectIfNotAuthenticatedGuard],
        children: [
            { path: '', loadChildren: () => import('./modules/users/user.module').then(m => m.UserModule) }
        ]
    },
    {
        path: 'insertions',
        data: { base: true },
        component: LayoutLoggedComponent,
        canActivate: [RedirectIfNotAuthenticatedGuard],
        children: [
            { path: '', loadChildren: () => import('./modules/insertions/insertion.module').then(m => m.InsertionModule) }
        ]
    },
    {
        path: 'support',
        data: { base: true },
        component: LayoutLoggedComponent,
        canActivate: [RedirectIfNotAuthenticatedGuard],
        children: [
            { path: '', loadChildren: () => import('./modules/contacts/contact.module').then(m => m.ContactModule) }
        ]
    },
    /* {
        path: 'support',
        data: { base: true },
        component: LayoutLoggedComponent,
        canActivate: [RedirectIfNotAuthenticatedGuard],
        children: [
            { path: '', loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule) }
        ]
    }, */
    {
        path: 'maintenance-flow',
        data: { base: true },
        component: LayoutLoggedComponent,
        canActivate: [RedirectIfNotAuthenticatedGuard],
        children: [
            { path: '', loadChildren: () => import('./modules/maintenance-flow/maintenance-flow.module').then(m => m.MaintenanceFlow) }
        ]
    },
    {
        path: 'auth/change-password',
        data: { base: true },
        component: LayoutLogoutComponent,
        canActivate: [RedirectIfAuthenticatedGuard],
        children: [
            //{ path: '', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
            { path: ':token', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) }
        ]
    },
    {
        path: 'login',
        data: { base: true },
        component: LayoutLogoutComponent,
        canActivate: [RedirectIfAuthenticatedGuard],
        children: [
            { path: '', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
            { path: 'change-password/:token', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) }
        ]
    },
    {
        path: 'auth-platform',
        data: { base: true },
        component: LayoutLogoutComponent,
        canActivate: [RedirectIfAuthenticatedGuard],
        children: [
            { path: '', loadChildren: () => import('./modules/auth-platform/platform.module').then(m => m.AuthPlatformModule) }
        ]
    },
    {
        path: 'trash',
        data: { base: true },
        component: LayoutLoggedComponent,
        canActivate: [RedirectIfNotAuthenticatedGuard],
        children: [
            { path: '', loadChildren: () => import('./modules/trash/trash.module').then(m => m.TrashModule) }
        ]
    },
    {
        path: 'admin',
        data: { base: true },
        component: LayoutLoggedComponent,
        canActivate: [RedirectIfNotAuthenticatedGuard],
        children: [
            { path: '', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) }
        ]
    },
    {
        path: 'downloads',
        data: { base: true },
        component: LayoutLoggedComponent,
        canActivate: [RedirectIfNotAuthenticatedGuard],
        children: [
            { path: '', loadChildren: () => import('./modules/downloads/downloads.module').then(m => m.DownloadsModule) }
        ]
    },
    {
        path: 'error', data: { base: true }, component: LayoutLogoutComponent, children: [
            { path: '', loadChildren: () => import('./modules/errors/error.module').then(m => m.ErrorModule) }
        ]
    },
    {
        path: 'administrative',
        data: { base: true },
        component: LayoutLoggedComponent,
        canActivate: [RedirectIfNotAuthenticatedGuard],
        children: [
            { path: '', loadChildren: () => import('./modules/administrative/administrative.module').then(m => m.AdministrativeModule) }
        ]
    },
    { path: '**', redirectTo: '/error/404' }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}
